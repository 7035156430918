@import '../../Styles/colors.dashboard.scss';

.map-box-container {
  .mapboxgl-popup-content {
    background-color: $white !important;
    color: $card-title !important;
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: $white !important;
  }

  .mapboxgl-popup-close-button,
  .mapboxgl-popup-close-button:hover {
    display: none;
  }
}
